<template>
  <div class="hold-transition">
    <div
      class="modal fade shadow"
      id="modal-form-detalle-solicitud"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="z-index: 10500"
      data-backdrop="static"
      data-keyboard="false"
    >
      <loading v-if="cargando" />
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              {{ accion }} Detalle Solicitud
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 justify-content-center row">
                <div class="col-md-2 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label for="fecha_inicial">Fecha Inicial</label>
                    <input
                      type="date"
                      v-model="form.fecha_inicial"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.fecha_inicial.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validarFechas()"
                    />
                  </div>
                </div>
                <div
                  class="col-md-1 col-sm-12 col-xs-12"
                  v-if="turno && turno.dia_turno && turno.dia_turno.length > 0"
                >
                  <div class="form-group">
                    <label for="fecha_inicial">hora Inicial</label>
                    <input
                      type="text"
                      v-model="turno.dia_turno[0].hora_inicial"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label for="fecha_final">Fecha Final</label>
                    <input
                      type="date"
                      v-model="form.fecha_final"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.fecha_final.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @input="validarFechas()"
                    />
                  </div>
                </div>
                <div
                  class="col-md-1 col-sm-12 col-xs-12"
                  v-if="turno && turno.dia_turno && turno.dia_turno.length > 0"
                >
                  <div class="form-group">
                    <label for="fecha_inicial">hora Final</label>
                    <input
                      type="text"
                      v-model="turno.dia_turno[0].hora_final"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label for="turno">Turno</label>
                    <v-select
                      class="p-0 rounded"
                      :class="
                        $v.form.turno_id.$invalid
                          ? 'border border-danger'
                          : 'border border-success'
                      "
                      v-model="turno"
                      placeholder="Turno"
                      label="nombre"
                      :options="$parent.listasForms.turnos"
                      @input="selectTurno()"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="tipo_vh">Tipo Vehículo</label>
                  <v-select
                    class="p-0 rounded"
                    :class="
                      $v.form.tipo_vehiculo_id.$invalid
                        ? 'border border-danger'
                        : 'border border-success'
                    "
                    v-model="tipo_vehiculo"
                    placeholder="Tipo Vehículo"
                    label="nombre"
                    :options="$parent.listasForms.tipos_vehiculos"
                    @input="selectTipoVh()"
                  >
                  </v-select>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="empresa">Empresa</label>
                  <v-select
                    class="p-0 rounded"
                    :class="
                      $v.form.empresa_id.$invalid
                        ? 'border border-danger'
                        : 'border border-success'
                    "
                    v-model="empresa"
                    placeholder="Empresa"
                    label="razon_social"
                    :options="$parent.listasForms.empresas"
                    @input="selectEmpresa()"
                  >
                  </v-select>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="ruta">Ruta</label>
                  <v-select
                    class="p-0 rounded"
                    :class="
                      $v.form.tif_ruta_id.$invalid
                        ? 'border border-danger'
                        : 'border border-success'
                    "
                    v-model="ruta"
                    placeholder="Ruta"
                    label="nombre"
                    :options="$parent.listasForms.rutas"
                    @input="selectRuta()"
                  >
                  </v-select>
                </div>
              </div>
              <div class="col-md-2 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="cantidad_vehiculos">Can. Vehículos</label>
                  <input
                    type="number"
                    v-model="form.cantidad_vehiculos"
                    :class="
                      $v.form.cantidad_vehiculos.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="producto">Producto Líquido</label>
                  <v-select
                    class="p-0 rounded"
                    :class="
                      $v.form.producto_id.$invalid
                        ? 'border border-danger'
                        : 'border border-success'
                    "
                    v-model="producto"
                    placeholder="Producto"
                    label="nombre"
                    :options="$parent.listasForms.productosLiquidos"
                    @input="selectProdLiqui()"
                  >
                  </v-select>
                </div>
              </div>
              <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="tipo_tarifa">Tipo Tarifa</label>
                  <select
                    class="form-control form-control-sm"
                    :class="
                      $v.form.tipo_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    v-model="form.tipo_tarifa"
                  >
                    <option value="">seleccione...</option>
                    <option
                      v-for="tarifa in $parent.listasForms.tipos_tarifas"
                      :key="tarifa.numeracion"
                      :value="tarifa.numeracion"
                    >
                      {{ tarifa.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="accesorios">Accesorios</label>
                  <input
                    type="text"
                    v-model="form.accesorios"
                    :class="
                      $v.form.accesorios.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="bloques">Bloque</label>
                  <v-select
                    class="p-0 rounded"
                    :class="
                      $v.form.bloque_id.$invalid
                        ? 'border border-danger'
                        : 'border border-success'
                    "
                    v-model="bloque"
                    placeholder="Bloque"
                    label="nombre"
                    :options="$parent.listasForms.bloques"
                    @input="[selectBloque(), getOperacionesSitio()]"
                  >
                  </v-select>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="form.bloque_id && loanding"
              >
                <div>
                  <label>Operación</label>
                </div>
                <div class="spinner-border text-success" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="form.bloque_id && !loanding"
              >
                <div class="form-group">
                  <label>Operación</label>
                  <v-select
                    class="p-0 rounded"
                    :class="
                      $v.form.operacion_id.$invalid
                        ? 'border border-danger'
                        : 'border border-success'
                    "
                    v-model="operacion"
                    placeholder="Operaciones"
                    label="Noperacion"
                    :options="listasForms.operacionesSitio"
                    @input="selectOperacion()"
                  >
                  </v-select>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="card card-info card-outline ">
                <div class="card-header pt-2 pb-2">
                  <div class="row justify-content-between">
                    <h3 class="card-title text-black text-bold">
                      <i class="fa fa-file-signature text-navy"></i> Asignación
                      Cargos Contables
                    </h3>
                    <div class="form-group col-md-8">
                      <label>Gerente Area</label>
                      <div class="row">
                        <div class="col-md-8">
                          <v-select
                            class="form-control form-control-sm p-0 mr-3 p-0"
                            :class="[
                              $v.gerente.$invalid ? 'is-invalid' : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="gerente"
                            placeholder="Gerente Area"
                            label="funcionario"
                            :options="$parent.listasForms.gerentes"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                    <div class="card-tools mr-0">
                      <button
                        type="button"
                        class="btn bg-frontera text-white"
                        @click="addContable()"
                        :disabled="
                          estado_contable == 1 ||
                            totalPresupuesto == 100 ||
                            $v.gerente.$invalid
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                    <div class="card-tools ml-0">
                      <button
                        type="button"
                        class="btn bg-danger text-white"
                        @click="destroyContable('completo')"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                    <div
                      v-if="!$v.gerente.$invalid"
                      class="col-md-12 mb-0 text-center"
                      :class="
                        totalPresupuesto == 100
                          ? 'text-success'
                          : totalPresupuesto < 100
                          ? 'text-warning'
                          : 'text-danger'
                      "
                    >
                      <h4>Porcentaje Total: {{ totalPresupuesto }}%</h4>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!$v.gerente.$invalid"
                  class="card-body table-responsive p-5"
                >
                  <table
                    class="table  table-bordered table-striped table-hover table-sm p-2 shadow-sm"
                  >
                    <thead class="bg-dark">
                      <tr>
                        <th class="text-center">Area</th>
                        <th class="text-center">Tipo Presupuesto</th>
                        <th class="text-center">CECO/AFE/G&A</th>
                        <th class="text-center text-nowrap">Responsable</th>
                        <th class="text-center">Activo Producción</th>
                        <th class="text-center" style="min-width: 12em">
                          % de la operación para cargar este CECO/AFE
                        </th>
                        <th style="width: 10em;">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody id="tbody">
                      <tr
                        v-for="(tifContable, index) in tifContables"
                        :key="tifContable.id"
                      >
                        <td class="text-center">
                          <div
                            v-if="
                              id_actualizar_contable == index &&
                                estado_contable == 2
                            "
                          >
                            <v-select
                              class="form-control form-control-sm mr-3 p-0"
                              :class="
                                $v.contable.area.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              v-model="area"
                              placeholder="Areas"
                              label="descripcion"
                              :options="$parent.listasForms.areas"
                              @input="getSelectArea()"
                            ></v-select>
                          </div>
                          <div v-else>
                            {{ tifContable.nArea }}
                          </div>
                        </td>
                        <td class="text-center">
                          <div
                            v-if="
                              id_actualizar_contable == index &&
                                estado_contable == 2
                            "
                          >
                            <v-select
                              class="form-control form-control-sm mr-3 p-0"
                              :class="[
                                $v.contable.tipo_presupuesto.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="contable.tipoPresupuesto"
                              placeholder="Tipo Presupuesto"
                              label="descripcion"
                              :options="$parent.listasForms.tipos_presupuesto"
                              @input="[resetCecoAfe(), getCecosAfes()]"
                            ></v-select>
                          </div>
                          <span
                            v-else
                            class="badge"
                            :class="
                              tifContable.tipo_presupuesto == 1
                                ? 'bg-info'
                                : tifContable.tipo_presupuesto == 2
                                ? 'bg-navy'
                                : 'bg-lime'
                            "
                          >
                            {{
                              tifContable.tipo_presupuesto
                                ? tifContable.nTipoPresupuesto
                                : null
                            }}
                          </span>
                        </td>
                        <td class="text-center">
                          <div
                            v-if="
                              id_actualizar_contable == index &&
                                estado_contable == 2
                            "
                          >
                            <v-select
                              v-model="ceco_afe"
                              placeholder="Ceco Afe"
                              label="codigo_contable"
                              :options="listasForms.ceco_afes"
                              class="form-control form-control-sm mr-3 p-0"
                              :class="
                                $v.contable.ceco_afe_id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @input="getSelectCeco()"
                              :disabled="contable.tipo_presupuesto == null"
                            ></v-select>
                          </div>
                          <div v-else>
                            {{ tifContable.ceco_afe.codigo_contable }}
                          </div>
                        </td>
                        <td class="text-center">
                          <div
                            v-if="
                              id_actualizar_contable == index &&
                                estado_contable == 2
                            "
                          >
                            <v-select
                              class="form-control form-control-sm p-0 mr-3 p-0"
                              :class="[
                                $v.contable.responsable_id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="responsable"
                              placeholder="Nombre Responsable"
                              label="funcionario"
                              :options="$parent.listasForms.responsables"
                              @input="getSelectResposable"
                            ></v-select>
                          </div>
                          <div v-else>
                            <div v-if="tifContable.responsable.nombres">
                              {{
                                tifContable.responsable.nombres
                                  ? tifContable.responsable.nombres +
                                    " " +
                                    tifContable.responsable.apellidos
                                  : ""
                              }}
                            </div>

                            <div v-else>
                              {{ tifContable.responsable.funcionario }}
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          <div
                            v-if="
                              id_actualizar_contable == index &&
                                estado_contable == 2
                            "
                          >
                            <v-select
                              class="form-control form-control-sm mr-3 p-0"
                              :class="[
                                $v.contable.activo_produccion.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="activo_produccion"
                              placeholder="Tipo Presupuesto"
                              label="descripcion"
                              :options="$parent.listasForms.activosProduccion"
                              @input="getSelectActivosProduccion"
                            ></v-select>
                          </div>
                          <div v-else>
                            {{ tifContable.nActivoProduccion }}
                          </div>
                        </td>
                        <td class="text-center">
                          <div
                            v-if="
                              id_actualizar_contable == index &&
                                estado_contable == 2
                            "
                          >
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              :class="
                                $v.contable.porcentaje.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              v-model="contable.porcentaje"
                            />
                          </div>
                          <div v-else>{{ tifContable.porcentaje }}</div>
                        </td>
                        <td>
                          <div
                            class="btn-group float-right"
                            v-if="estado_contable != 2"
                          >
                            <button
                              type="button"
                              class="btn bg-navy"
                              :disabled="estado_contable != -1"
                              @click="editContable(index)"
                              v-if="
                                $store.getters.can(
                                  'funcionariofrontera.tifSolicitudes.editDetalle'
                                )
                              "
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              class="btn bg-danger"
                              :disabled="estado_contable != -1"
                              @click="destroyContable(index)"
                              v-if="
                                $store.getters.can(
                                  'funcionariofrontera.tifSolicitudes.deleteDetalle'
                                )
                              "
                            >
                              <i class="fas fa-trash"></i>
                            </button>
                          </div>
                          <div
                            class="btn-group float-right"
                            v-if="
                              estado_contable == 2 &&
                                id_actualizar_contable == index
                            "
                          >
                            <button
                              type="button"
                              class="btn bg-success"
                              v-show="!$v.contable.$invalid"
                              @click="saveUpdateContable(index)"
                              v-if="
                                $store.getters.can(
                                  'funcionariofrontera.tifSolicitudes.editDetalle'
                                )
                              "
                            >
                              <i class="fas fa-save"></i>
                            </button>
                            <button
                              class="btn bg-danger"
                              @click="cancelContable()"
                            >
                              <i class="fas fa-ban"></i>
                            </button>
                          </div>
                        </td>
                      </tr>

                      <tr v-if="estado_contable == 1">
                        <td>
                          <v-select
                            class="form-control form-control-sm mr-3 p-0"
                            v-model="area"
                            :class="
                              $v.contable.area.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            placeholder="Areas"
                            label="descripcion"
                            :options="$parent.listasForms.areas"
                            @input="getSelectArea()"
                          ></v-select>
                        </td>
                        <td>
                          <v-select
                            class="form-control form-control-sm mr-3 p-0"
                            :class="[
                              $v.contable.tipo_presupuesto.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="contable.tipoPresupuesto"
                            placeholder="Tipo Presupuesto"
                            label="descripcion"
                            :options="$parent.listasForms.tipos_presupuesto"
                            @input="[resetCecoAfe(), getCecosAfes()]"
                          ></v-select>
                        </td>
                        <td>
                          <v-select
                            v-model="ceco_afe"
                            placeholder="Ceco Afe"
                            label="codigo_contable"
                            :options="listasForms.ceco_afes"
                            class="form-control form-control-sm mr-3 p-0"
                            :class="
                              $v.contable.ceco_afe_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="getSelectCeco()"
                            :disabled="contable.tipo_presupuesto == null"
                          ></v-select>
                        </td>
                        <td>
                          <v-select
                            class="form-control form-control-sm p-0 mr-3 p-0"
                            :class="[
                              $v.contable.responsable_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="responsable"
                            placeholder="Nombre Responsable"
                            label="funcionario"
                            :options="$parent.listasForms.responsables"
                            @input="getSelectResposable"
                          ></v-select>
                        </td>
                        <td>
                          <v-select
                            class="form-control form-control-sm p-0 mr-3 p-0"
                            :class="[
                              $v.contable.activo_produccion.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="activo_produccion"
                            placeholder="Tipo Presupuesto"
                            label="descripcion"
                            :options="$parent.listasForms.activosProduccion"
                            @input="getSelectActivosProduccion"
                          ></v-select>
                        </td>
                        <td>
                          <input
                            type="number"
                            class="form-control form-control-sm p-0 mr-3 p-0"
                            id="porcentaje"
                            v-model="contable.porcentaje"
                            :class="
                              $v.contable.porcentaje.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </td>
                        <td>
                          <div class="btn-group float-right">
                            <button
                              class="btn bg-success"
                              v-show="!$v.contable.$invalid"
                              @click="saveContable()"
                              v-if="
                                $store.getters.can(
                                  'funcionariofrontera.tifSolicitudes.createDetalle'
                                )
                              "
                            >
                              <i class="fas fa-save"></i>
                            </button>
                            <button
                              class="btn bg-danger"
                              @click="cancelContable()"
                            >
                              <i class="fas fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="modal-footer justify-content-between p-0">
              <button
                type="button"
                class="btn btn-success shadow"
                v-show="
                  !$v.form.$invalid &&
                    totalPresupuesto == 100 &&
                    !$v.gerente.$invalid
                "
                @click="saveTotal()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import axios from "axios";
export default {
  name: "DetSolicitudForm",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      loanding: false,
      accion: null,
      metodo: null,
      totalPresupuesto: 0,
      form: {
        id: null,
        tif_solicitud_id: null,
        tipo_vehiculo_id: null,
        cantidad_vehiculos: null,
        turno_id: null,
        empresa_id: null,
        tif_ruta_id: null,
        producto_id: null,
        tipo_tarifa: null,
        accesorios: null,
        bloque_id: [],
        operacion_id: [],
      },
      operacion: [],
      gerente: [],
      turno: [],
      empresa: [],
      tipo_vehiculo: [],
      producto: [],
      ruta: [],
      area: [],
      responsable: [],
      activo_produccion: [],
      contable: {},
      ceco_afe: {},
      tifContables: [],
      bloque: [],
      id_actualizar_contable: -1,
      estado_contable: -1,
      listasForms: {
        operacionesSitio: [],
        ceco_afes: [],
      },
    };
  },

  validations: {
    form: {
      cantidad_vehiculos: {
        required,
      },
      empresa_id: {
        required,
      },
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
      producto_id: {
        required,
      },
      tif_ruta_id: {
        required,
      },
      tipo_tarifa: {
        required,
      },
      tipo_vehiculo_id: {
        required,
      },
      turno_id: {
        required,
      },
      accesorios: {
        required,
      },
      bloque_id: {
        required,
      },
      operacion_id: {
        required,
      },
    },

    contable: {
      tipo_presupuesto: {
        required,
      },
      ceco_afe_id: {
        required,
      },
      codigo_ceco_afe: {
        required,
      },
      porcentaje: {
        required,
      },
      area: {
        required,
      },
      activo_produccion: {
        required,
      },
      responsable_id: {
        required,
      },
    },
    gerente: {
      required,
    },
  },

  methods: {
    async getIndex(data) {
      //this.cargando = true;
      this.id_actualizar_contable = -1;
      this.tifContables = [];
      this.gerente = [];
      this.estado_contable = -1;
      this.limpiarForm();
      this.limpiarContable();
      if (data.accion == "Crear") {
        this.accion = data.accion;
        this.metodo = "post";
        this.calcularContable();
      }
      if (data.accion == "Editar") {
        this.accion = data.accion;
        this.metodo = "put";
        this.form = {
          id: data.detalle.id,
          tif_solicitud_id: this.$parent.form.id,
          cantidad_vehiculos: data.detalle.cantidad_vehiculos,
          empresa_id: data.detalle.empresa_id,
          fecha_inicial: data.detalle.fecha_inicial.split(" ")[0],
          fecha_final: data.detalle.fecha_final.split(" ")[0],
          producto_id: data.detalle.producto_id,
          tif_ruta_id: data.detalle.tif_ruta_id,
          tipo_tarifa: data.detalle.tipo_tarifa,
          tipo_vehiculo_id: data.detalle.tipo_vehiculo_id,
          turno_id: data.detalle.turno_id,
          accesorios: data.detalle.accesorios,
          bloque_id: data.detalle.bloque_id,
          operacion_id: data.detalle.operacion_id,
        };
        await this.getOperacionesSitio();
        this.form.operacion_id = data.detalle.operacion_id;
        this.empresa = data.detalle.empresa;
        this.tipo_vehiculo = data.detalle.tipo_vh;
        this.producto = data.detalle.producto;
        this.ruta = data.detalle.ruta;
        this.turno = this.$parent.listasForms.turnos.find(
          (turno) => turno.id === data.detalle.turno_id
        );
        data.detalle.turno;
        this.gerente = this.$parent.listasForms.gerentes.find(
          (funcionario) =>
            funcionario.id === data.detalle.contables[0].gerente_area_id
        );
        this.bloque = this.$parent.listasForms.bloques.find(
          (bloque) => bloque.id === data.detalle.bloque_id
        );

        this.operacion = data.detalle.operacion;

        //contables
        this.tifContables = [];
        data.detalle.contables.forEach((cont) => {
          this.tifContables.push({
            id: cont.id,
            tif_det_solicitud_id: cont.tif_det_solicitud_id,
            tipo_presupuesto: cont.tipo_presupuesto,
            nTipoPresupuesto: cont.nTipoPresupuesto,
            area: cont.area,
            nArea: cont.nArea,
            activo_produccion: cont.activo_produccion,
            nActivoProduccion: cont.nActivoProduccion,
            responsable: cont.responsable,
            responsable_id: cont.responsable.id,
            ceco_afe_id: cont.ceco_afe.id,
            codigo_ceco_afe: cont.ceco_afe.codigo_contable,
            porcentaje: parseFloat(cont.porcentaje),
            tipoPresupuesto: this.$parent.listasForms.tipos_presupuesto.find(
              (e) => e.numeracion == cont.tipo_presupuesto
            ),
            ceco_afe: cont.ceco_afe,
          });
        });
        this.calcularContable();
        this.cargando = false;
      }
    },

    selectEmpresa() {
      this.form.empresa_id = this.empresa ? this.empresa.id : "";
    },
    selectBloque() {
      this.form.bloque_id = this.bloque ? this.bloque.id : "";
    },
    selectOperacion() {
      this.form.operacion_id = this.operacion ? this.operacion.id : "";
    },
    selectTipoVh() {
      this.form.tipo_vehiculo_id = this.tipo_vehiculo
        ? this.tipo_vehiculo.id
        : "";
    },

    selectProdLiqui() {
      this.form.producto_id = this.producto ? this.producto.id : "";
    },

    selectRuta() {
      this.form.tif_ruta_id = this.ruta ? this.ruta.id : "";
    },

    selectTurno() {
      this.form.turno_id = this.turno ? this.turno.id : "";
    },

    async getOperacionesSitio() {
      this.loanding = true;
      this.operacion = [];
      this.form.operacion_id = null;
      /* let data = this.form.bloques
        ? { bloques: this.form.bloques.map((bloque) => bloque.id) }
        : { bloques: [] };

      this.form.operaciones =
        this.form.operaciones.length > 0
          ? this.form.operaciones.filter((operacion) =>
              data.bloques.includes(operacion.bloque_id)
            )
          : []; */

      await axios({
        method: "GET",
        url: "/api/tif/sitios/operacionesSitio",
        params: this.form.bloque_id,
      }).then(async (response) => {
        this.listasForms.operacionesSitio = await response.data;
        this.listasForms.operacionesSitio = this.listasForms.operacionesSitio.filter(
          (operacion) => !operacion.Noperacion.includes("Camiones de Vacío")
        );
        this.loanding = false;
      });
    },

    getSelectArea() {
      this.contable.area = null;
      this.contable.nArea = null;
      if (this.area) {
        this.contable.area = this.area.numeracion;
        this.contable.nArea = this.area.descripcion;
      }
    },

    resetCecoAfe() {
      this.contable.ceco_afe = null;
      this.contable.ceco_afe_id = null;
      this.ceco_afe = null;
    },

    async getCecosAfes() {
      this.contable.tipo_presupuesto = null;
      if (this.contable.tipoPresupuesto) {
        this.contable.tipo_presupuesto = this.contable.tipoPresupuesto.numeracion;
        this.contable.nTipoPresupuesto = this.contable.tipoPresupuesto.descripcion;
        await axios
          .get("/api/funcionariosFrontera/wsObjetosActivos/lista", {
            params: {
              tipo_presupuesto: this.contable.tipo_presupuesto,
              linea_negocio_id: 3,
            },
          })
          .then(async (response) => {
            this.listasForms.ceco_afes = response.data;
            if (this.listasForms.ceco_afes.length == 0) {
              await this.$swal({
                icon: "warning",
                title: "Advertencia!!",
                text:
                  "No se encontró datos para el Tipo Presupuesto. Por favor validar.!",
                timer: 4000,
                backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
                timerProgressBar: true,
                confirmButtonText: "Aceptar",
                customClass: {
                  popup: "swal-custom-popup", // Custom class for swal popup
                  container: "my-swal",
                },
                didOpen: () => {
                  // Esto asegura que el SweetAlert se muestre sobre la modal
                  const modal = document.getElementById(
                    "modal-form-detalle-solicitud"
                  );
                  if (modal) {
                    modal.style.zIndex = 10700; // Un valor mayor que la alerta
                  }
                },
              });
            }
          });
      }
    },

    getSelectCeco() {
      this.contable.ceco_afe_id = null;
      if (this.ceco_afe) {
        this.contable.ceco_afe = { ...this.ceco_afe };
        this.contable.ceco_afe_id = this.ceco_afe.id;
        this.contable.codigo_ceco_afe = this.ceco_afe.codigo_contable;
      }
    },

    getSelectResposable() {
      this.contable.responsable_id = null;
      if (this.responsable) {
        this.contable.responsable_id = this.responsable.id;
        this.contable.responsable = this.responsable;
      }
    },

    getSelectActivosProduccion() {
      this.contable.activo_produccion = null;
      this.contable.nActivoProduccion = null;
      if (this.activo_produccion) {
        this.contable.activo_produccion = this.activo_produccion.numeracion;
        this.contable.nActivoProduccion = this.activo_produccion.descripcion;
      }
    },

    async addContable() {
      await this.limpiarContable();
      this.estado_contable = 1;
    },

    async limpiarContable() {
      this.contable = {
        id: null,
        tipo_presupuesto: null,
        ceco_afe_id: null,
        codigo_ceco_afe: null,
        porcentaje: null,
        tif_det_solicitud_id: null,
        tipoPresupuesto: null,
        ceco_afe: null,
        area: null,
        activo_produccion: null,
        responsable_id: null,
        gerente_area_id: null,
      };
      this.responsable = {};
      this.activo_produccion = {};
      this.area = {};
      this.ceco_afe = {};
    },

    async limpiarForm() {
      this.form = {
        id: null,
        tif_solicitud_id: this.$parent.form.id,
        tipo_vehiculo_id: null,
        cantidad_vehiculos: null,
        turno_id: null,
        empresa_id: null,
        tif_ruta_id: null,
        producto_id: null,
        tipo_tarifa: null,
        accesorios: null,
        bloque_id: null,
        operacion_id: null,
      };
      this.bloque = [];
      this.operacion = [];
      this.empresa = [];
      this.tipo_vehiculo = [];
      this.producto = [];
      this.ruta = [];
      this.turno = [];
    },

    async cancelContable() {
      this.limpiarContable();
      this.estado_contable = -1;
    },

    async saveContable() {
      let me = this;
      await me.calcularContable();
      if (
        me.totalPresupuesto + parseFloat(me.contable.porcentaje) <= 100 &&
        me.contable.porcentaje > 0
      ) {
        me.tifContables.push(me.contable);
        me.estado_contable = -1;
        await me.calcularContable();
      } else {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error... El porcentaje no puede ser mayor al 100%",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          customClass: {
            container: "my-swal",
          },
        });
      }
    },

    async calcularContable() {
      let totalPresupuesto = 0;
      this.tifContables.forEach((element) => {
        totalPresupuesto += parseFloat(element.porcentaje);
      });
      this.totalPresupuesto = totalPresupuesto;
      return totalPresupuesto;
    },

    async editContable(index) {
      this.limpiarContable();
      this.ceco_afe = this.tifContables[index].ceco_afe;
      this.ceco_afe;
      this.id_actualizar_contable = index;
      this.estado_contable = 2;
      this.contable = { ...this.tifContables[index] };

      this.contable.tipoPresupuesto = this.$parent.listasForms.tipos_presupuesto.find(
        (item) => item.numeracion === this.contable.ceco_afe.tipo_presupuesto
      );

      this.area = this.$parent.listasForms.areas.find(
        (item) => item.numeracion === this.contable.area
      );

      this.activo_produccion = this.$parent.listasForms.activosProduccion.find(
        (item) => item.numeracion === this.contable.activo_produccion
      );

      this.responsable = this.$parent.listasForms.responsables.find(
        (item) => item.id === this.contable.responsable_id
      );

      this.ceco_afe_obj = { ...this.tifContables[index].ceco_afe_obj };
      await this.getCecosAfes();
    },

    async saveUpdateContable(index) {
      let me = this;
      let sumaPorcentaje = 0;
      if (index != null) {
        this.tifContables.forEach((element, key) => {
          if (key !== index) {
            sumaPorcentaje += parseFloat(element.porcentaje);
          }
        });
      } else {
        this.tifContables.forEach((element) => {
          sumaPorcentaje += parseFloat(element.porcentaje);
        });
      }
      let total_presupuesto =
        sumaPorcentaje + parseFloat(this.contable.porcentaje);
      if (total_presupuesto > 100) {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error... El porcentaje no puede ser mayor al 100%",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          customClass: {
            container: "my-swal",
          },
        });
      } else {
        this.tifContables[index] = this.contable;
        this.estado_contable = -1;
        this.id_actualizar_contable = -1;
        await me.calcularContable();
      }
    },

    async destroyContable(index) {
      if (index == "completo") {
        this.$swal({
          title: "Quitar Cargos Contables.",
          text: "Está seguro de quitar los cargos contables?",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            container: "my-swal",
          },
          confirmButtonText: "Si, Quitar!",
        }).then((result) => {
          this.asignacionContable = null;
          this.tifContables = [];
          this.calcularContable();
        });
      } else {
        this.$swal({
          title: "Quitar Cargo Contable.",
          text: "Está seguro de quitar este cargo contable?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Quitar!",
          customClass: {
            container: "my-swal",
          },
        }).then((result) => {
          if (result.value) {
            this.tifContables.splice(index, 1);
            this.calcularContable();
          }
        });
      }
    },

    saveTotal() {
      this.cargando = true;
      this.form.fecha_inicial =
        this.form.fecha_inicial + " " + this.turno.dia_turno[0].hora_inicial;
      this.form.fecha_final =
        this.form.fecha_final + " " + this.turno.dia_turno[0].hora_final;

      this.tifContables = this.tifContables.map((item) => {
        return {
          ...item,
          gerente_area_id: this.gerente.id,
          tif_det_solicitud_id: this.form.id,
        };
      });

      let data = {
        detalle: this.form,
        contables: this.tifContables,
      };
      axios({
        method: this.metodo,
        url: "/api/funcionariosFrontera/tifSolicitudes/saveDetalle",
        data: data,
      })
        .then((response) => {
          this.$swal({
            text: "Se guardó el detalle correctamente...",
            icon: "success",
            confirmButtonText: "Ok!",
            customClass: {
              container: "my-swal",
            },
          });
          this.$refs.closeModal.click();
          this.$parent.getIndex();
          this.cargando = false;
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "Ha ocurrido un error, por favor intente la acción nuevamente.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {
              container: "my-swal",
            },
          });
        });
    },

    validarFechas() {
      if (this.form.fecha_inicial) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.form.fecha_inicial);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.form.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {
              container: "my-swal",
            },
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        var fecha_menor = new Date(this.form.fecha_inicial);
        var fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {
              container: "my-swal",
            },
          });
        }
      }
    },
  },
};
</script>
<style>
.my-swal {
  z-index: 20000 !important;
}
</style>
